import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import Layout from '../components/Layout'
import MetaImage from '../assets/images/meta/dx-core-4.png'
import CTABlock from '../components/blocks/CTABlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Stack from '../components/primitives/Stack'
import { Core4Table } from '../components/Core4table'
import Box from '../components/primitives/Box'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import { RowVariant } from '../components/Core4Comparison'
import Br from '../components/system/Br'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/benchmarks.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/product/investment-balance/multi-ic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(relativePath: { eq: "images/product/overview/target.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(relativePath: { eq: "images/product/surveys/question.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    mosaicRight1: file(
      relativePath: { eq: "images/mosaic/dx-core-4-650-560.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft1: file(
      relativePath: { eq: "images/mosaic/surveys-650-560.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/ci-650-560.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/investment-balance-high.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

// Type for each explanation.
export type BenchmarkExplanation = {
  key: string
  title: string
  tooltip?: string
  description: ReactNode
  rowVariant?: RowVariant
  isNested?: boolean
}

export const benchmarkExplanations = [
  {
    key: 'engineeringInvestment',
    title: 'Key metric',
    description: '',
  },
  {
    key: 'flowEfficiency',
    title: 'Secondary metrics',
    description: '',
    rowVariant: 'highlight',
  },
] as const satisfies readonly BenchmarkExplanation[]

export type ExplanationKey = (typeof benchmarkExplanations)[number]['key']

export type BenchmarkColumn = {
  title: string
  values: Record<ExplanationKey, ReactNode>
}

const benchmarksConfig: BenchmarkColumn[] = [
  {
    title: 'Speed',
    values: {
      engineeringInvestment: (
        <>
          Diffs per engineer* (PRs or MRs). <Br />
          *Not at individual level
          <Br />
        </>
      ),
      flowEfficiency: (
        <ul style={{ textAlign: 'left', margin: 0, padding: 10 }}>
          <li>Lead time</li>
          <li>Deployment frequency</li>
          <li>Perceived rate of delivery</li>
        </ul>
      ),
    },
  },
  {
    title: 'Effectiveness',
    values: {
      engineeringInvestment: (
        <>
          Developer Experience Index (DXI), measure of key engineering
          performance drivers
        </>
      ),
      flowEfficiency: (
        <ul style={{ textAlign: 'left', margin: 0, padding: 10 }}>
          <li>Time to 10th PR</li>
          <li>Ease of delivery</li>
          <li>Regrettable attrition at organizational level</li>
        </ul>
      ),
    },
  },
  {
    title: 'Quality',
    values: {
      engineeringInvestment: <>Change failure rate</>,
      flowEfficiency: (
        <ul style={{ textAlign: 'left', margin: 0, padding: 10 }}>
          <li>Failed deployment recovery time</li>
          <li>Perceived software quality</li>
          <li>Operational health and security metrics</li>
        </ul>
      ),
    },
  },
  {
    title: 'Impact',
    values: {
      engineeringInvestment: <>Percentage of time spent on new capabilities</>,
      flowEfficiency: (
        <ul style={{ textAlign: 'left', margin: 0, padding: 10 }}>
          <li>Initiative progress and ROI</li>
          <li>Revenue per engineer at organizational level</li>
          <li>R&D as percentage of revenue at organizational level</li>
        </ul>
      ),
    },
  },
]

const BenchmarksPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="How to roll out the DX Core 4 with Swarmia"
      variant="dark"
      isNew
      description="Start measuring developer productivity with the help of the Core 4 framework and Swarmia."
      metaImage={MetaImage}
    >
      <HeroBlock
        title="How to roll out the DX&nbsp;Core&nbsp;4&nbsp;with Swarmia"
        content="Start measuring developer productivity with the help of the Core 4 framework and Swarmia, the software engineering intelligence platform that combines proven engineering metrics with developer experience surveys."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <Box marginBottom={48}>
        <LeadBlock
          heading="The DX Core 4 dimensions&nbsp;and&nbsp;metrics"
          content="The DX Core 4 framework helps you measure developer productivity across four dimensions: speed, effectiveness, quality, and business impact. It works for organizations of all sizes and can be easily adapted to your company's needs."
        />
      </Box>
      <Stack maxWidth={1400} marginX="auto" space={24}>
        {/* Use Core4Table from Core4table file */}
        <Core4Table benchmarks={benchmarksConfig} />
      </Stack>
      <Box marginTop={48}>
        <LeadBlock
          heading="Get the complete view of DX&nbsp;Core&nbsp;4 with Swarmia"
          content="When you integrate the DX Core 4 with Swarmia, you integrate data from developer experience surveys with engineering metrics from your issue tracker and code repository. DX, on the other hand, recommends mainly relying on surveys, which can limit the effectiveness of the framework."
          link={{
            title: 'See how DX and Swarmia compare',
            href: '/alternative/dx/',
          }}
        />
        <MosaicBlock
          title={<>Speed</>}
          content={
            <>
              Swarmia gives organizations a real-time view of speed metrics.
              <br />
              <br />
              Analyze data at the organizational level, or break it down per
              team. To understand the “why” behind the numbers, use developer
              experience surveys and get concrete ideas for improvement.
            </>
          }
          image={getImage(data.mosaicRight1)!}
          imageAlign="right"
        />
        <MosaicBlock
          title={<>Effectiveness</>}
          content={
            <>
              Measure key effectiveness metrics around issue cycle time, scope
              creep, flow efficiency, and bug resolution.
              <br />
              <br />
              To better understand effectiveness, use our research-backed survey
              framework, or add the questions from the Developer Experience
              Index.
            </>
          }
          image={getImage(data.mosaicLeft1)!}
        />
        <QuoteBlock person="dave3" />
        <MosaicBlock
          title={<>Quality</>}
          content={
            <>
              Swarmia tracks change failure rate, recovery rate, and other
              quality metrics directly from issue tracker and code repository.
              <br />
              <br />
              To proactively avoid failed deployments and other quality issues,
              adopt working agreements to encourage teams to include automations
              and reviews in the deployment process.
            </>
          }
          image={getImage(data.mosaicRight2)!}
          imageAlign="right"
        />
        <MosaicBlock
          title={<>Impact</>}
          content={
            <>
              Make informed decisions about engineering investments based on
              data you can trust. See how much time teams are spending on
              building new things, reducing tech debt, and improving
              productivity.
              <br />
              <br />
              Software capitalization enables you to quickly and flexibly
              estimate and report costs. You can view the entire organization or
              zoom in on specific teams, epics, or bugs.
            </>
          }
          image={getImage(data.mosaicLeft2)!}
        />
        <Box marginTop={48}>
          <QuoteBlock person="tim2" />
          <CTABlock title="Start applying  the DX Core 4 with&nbsp;Swarmia&nbsp;today." />
        </Box>
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/developer-survey-retrospectives/',
            '/blog/change-lead-time/',
            '/blog/flow-software-development/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default BenchmarksPage
