import React, { ReactNode } from 'react'
import theme from '../styles/theme'
import Box, { BoxProps } from './primitives/Box'

type Column = {
  title: string
}

type Props = {
  title?: string
  selector: ReactNode
  children: ReactNode
}

export function ComparisonTable({ title, selector, children }: Props) {
  return (
    <>
      {title ? (
        <Box.h2 font="h2" textAlign="center" marginBottom={32}>
          {title}
        </Box.h2>
      ) : null}
      {selector}
      <Box
        overflow="hidden"
        borderRadius={16}
        paddingTop={32}
        paddingX={{ xs: 16, lg: 48 }}
        borderColor="gray200"
        css={`
          background: linear-gradient(180deg, #f7f8fb 0%, #f0f2f6 100%);
        `}
      >
        {children}
      </Box>
    </>
  )
}

export function SpacerRow({
  height,
  columns,
  selectedColumn,
  firstColumnWidth,
}: {
  height: BoxProps['height']
  columns: Column[]
  selectedColumn: string
  firstColumnWidth?: string
}) {
  return (
    <TableRow
      columns={columns}
      selectedColumn={selectedColumn}
      variant="normal"
      firstColumnWidth={firstColumnWidth}
    >
      <TableCell isSpacer>
        <Box height={height} />
      </TableCell>
      {Array.from({ length: columns.length }).map((_, i) => (
        <TableCell isSpacer key={`spacer-${i}`} />
      ))}
    </TableRow>
  )
}

export type RowVariant =
  | 'header'
  | 'tierHeader'
  | 'highlight'
  | 'normal'
  | 'highlightStart'
  | 'highlightMiddle'
  | 'highlightEnd'

export function TableRow({
  columns,
  selectedColumn,
  variant,
  children,
  firstColumnWidth = '1fr',
}: {
  columns: Column[]
  selectedColumn: string
  variant: RowVariant
  children: ReactNode
  firstColumnWidth?: string
}) {
  const selectedIndex = columns.findIndex(t => t.title === selectedColumn)
  const indexOffset = 2 // feature name col + 1-based indexing
  const variantBackgroundColors: Record<
    RowVariant,
    BoxProps['backgroundColor']
  > = {
    header: 'blue100',
    tierHeader: 'transparent',
    normal: 'transparent',
    highlight: 'white',
    highlightStart: 'white',
    highlightMiddle: 'white',
    highlightEnd: 'white',
  }
  const topRadius = ['highlightMiddle', 'highlightEnd'].includes(variant)
    ? undefined
    : 12
  const bottomRadius = ['highlightMiddle', 'highlightStart'].includes(variant)
    ? undefined
    : 12
  return (
    <Box
      display="grid"
      borderTopLeftRadius={topRadius}
      borderTopRightRadius={topRadius}
      borderBottomLeftRadius={bottomRadius}
      borderBottomRightRadius={bottomRadius}
      gridTemplateColumns={{
        xs:
          variant === 'tierHeader'
            ? `0 1fr`
            : `minmax(100px, ${firstColumnWidth}) minmax(100px, 1fr)`,
        lg: `repeat(${columns.length + 1}, 1fr)`,
      }}
      backgroundColor={variantBackgroundColors[variant]}
      css={`
        /* Responsive grid: show first column + selected plan column on mobile,
           all columns on larger screens. */
        > * {
          display: none;
        }
        > :first-child {
          display: flex;
        }
        > :nth-child(${selectedIndex + indexOffset}) {
          display: flex;
        }
        @media screen and (min-width: ${theme.breakpoints.lg}) {
          > * {
            display: flex;
          }
        }
      `}
    >
      {children}
    </Box>
  )
}

export function TableCell({
  isSpacer = false,
  children,
  ...boxProps
}: {
  isSpacer?: boolean
  children?: ReactNode
} & BoxProps) {
  return (
    <Box
      minWidth={160}
      paddingX={{ xs: 24, lg: 40 }}
      paddingY={isSpacer ? 0 : 20}
      font="textLabel"
      borderRightStyle="solid"
      borderRightColor="black100"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      css={`
        border-right-width: 0;
        :first-child {
          justify-content: flex-start;
          text-align: left;
          border-right-width: 0;
        }
        :last-child {
          border-right-width: 0;
        }
        @media screen and (min-width: ${theme.breakpoints.lg}) {
          border-right-width: 1px;
        }
      `}
      {...boxProps}
    >
      {children}
    </Box>
  )
}
